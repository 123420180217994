
.container {
    height: 100%;
    width: 100%;
    
  }
  .infobox {
    width: "100%";
    display: flex;
   
  
    flex-direction: column;
  }
  .reviewbox {
    width: "100%";
    display: flex;
    
    border-top: 1px solid;
    flex-direction: row;
  }
  .reviewbox2 {
    width: "100%";
    
    display: flex;
    align-items: center;
    margin-left: 7px;
    flex-direction: row;
  
  }
  .reviewbox3 {
    width: "100%";
  
    display: flex;
    align-items: center;
    margin-left: 7px;
    
    flex-direction: row;
  
  }
  .endbox {
    width: "100%";
    display: flex;

    flex-direction: column;
    justify-content: flex-end;
  
  }
  