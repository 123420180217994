.carousel-img {
    height: 75vh;
    
    margin: 0 auto 3%;
    border-radius: 50%;
  }
  .carousel-indicators{
    
    display: none;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 0px;
    margin-left: 15%;
    list-style: none;
  }
