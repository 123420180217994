body {
    background-color: #181A18;
  }

.darkened {
  background-blend-mode: darken, luminosity;
}

.image-blurred-edge {
  /* you need to match the shadow color to your background or image border for the desired effect*/
  box-shadow: 0 0 5px 5px #181A18 inset;
}
