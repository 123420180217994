@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding: 0;
  margin: 0;
}

.maincontainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

}
.infobox {
  width: "100%";
  display: flex;
 

  flex-direction: column;
}
.leaflet-container {
  height: 100%;
  width: 100%;
}

.reviewbox {
  width: "100%";
  display: flex;
  
  border-top: 1px solid;
  flex-direction: row;
}
.reviewbox2 {
  width: "100%";
  
  display: flex;
  align-items: center;
  margin-left: 7px;
  flex-direction: row;

}
.reviewbox3 {
  width: "100%";

  display: flex;
  align-items: center;
  margin-left: 7px;
  flex-direction: row;

}


