.search-bar-dropdown {
    width: 16em;
  }
  
  #results {
    position: absolute;
    width: inherit;  
    overflow-y: scroll;
    max-height: 20em;
    
    
    /* border-bottom: 1px #eeeeee solid; */
  }
  
  #results > button {
    background-color: #fafafa;
  }

  #results > button:hover {
    background-color: #2f2f2f;
    
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height:inherit
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background-color: #eeeeee;
    
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  #search-bar {
    width: 100%;
  }
  